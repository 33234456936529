<!--
 * @Descripttion: 操作列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:17:46
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-27 16:56:38
-->
<template>
    <div class="operationList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">操作列表</div>
        </div>
        <div class="case-wrap">
            <commonSelect 
                @changeDate="changeDate"
                @clickTagDate="clickTagDate"
            ></commonSelect>
        </div>
        <div class="case-wrap">
            <div class="table-wrap" >
                <div class="title-wrap">
                    <div class="select-wrap">
                        <el-select clearable v-model="userId" @change="selectChange" placeholder="请选择用户筛选">
                            <el-option
                                v-for="item in userOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-wrap">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border style="width: 100%" v-loading="loading">
                    <el-table-column align="center" prop="id" label="ID" width="100"></el-table-column>
                    <el-table-column align="center" prop="username" label="操作人"></el-table-column>
                    <el-table-column align="center" prop="describe" label="功能描述" ></el-table-column>
                    <el-table-column align="center" prop="targetId" label="目标ID" ></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="操作时间" ></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import commonSelect from "@/components/commonSelect.vue"
import common from "@/utils/common.js"
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'operationList',
            userInfo: {},
            tableData: [],
            loading: false,
            keyword: '',
            userId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            currentPage: 1,
            total: 0,
            userOptions: []
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getIndexList()
        this.getUserConditions();
    },
    methods: {
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+'00:00:01';
            this.endDate = endDate.slice(0,11)+'23:59:59';
            this.getIndexList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getIndexList();
            }
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getIndexList()
        },
        // 更据用户筛选
        selectChange(){
            this.currentPage = 1;
            this.getIndexList();
        },
        // 获取用户列表
        getUserConditions(){
            common.connect("/distributorpcv1/user/userConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                    return{
                        value: v.user_id, 
                        label: v.username
                    }
                })
                this.userOptions = resultSecond;
            });
        },
        // 获取列表数据
        getIndexList(){
            let params = {
                keyword: this.keyword,
                user_id: this.userId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/distributorpcv1/log/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIndexList();
        }
    },
}
</script>

<style lang='scss'>
.operationList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .table-wrap{
            .title-wrap{
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                .select-wrap{
                    margin-right: 20px;
                }
                .search-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .el-input{
                        width: 200px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
}
</style>
